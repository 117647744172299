import React from "react";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "../ui/select";
import { countryCodes } from "@/src/utils/general";
import { useGetJoinInfo, useJoinWaitlist } from "@/src/store/slices/waitlist";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { JoinWaitlistType, JoinWaitlistSchema } from "../../types/waitlist";
import { LoaderButton } from "../ui-extended/loader-button";

const UserJoinInformation: React.FC = () => {
	const [countryCode, setCountryCode] = React.useState("+1");
	const getWaitlistInfoQuery = useGetJoinInfo();
	const joinWaitlistMutation = useJoinWaitlist();

	const {
		register,
		handleSubmit,
		// setError,
		formState: { errors },
	} = useForm<JoinWaitlistType>({
		// defaultValues: { custom_intakes: {} },
		resolver: zodResolver(JoinWaitlistSchema),
	});

	const onSubmit: SubmitHandler<JoinWaitlistType> = (data) => {
		joinWaitlistMutation.mutate({
			...data,
			phone_number: countryCode + data.phone_number,
		});
	};

	return (
		<section className="flex flex-col items-center space-y-16 p-4">
			<form
				className="w-full rounded-xl bg-white p-6"
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className="flex flex-col space-y-5">
					<h1 className="text-[20px] font-semibold leading-[140%] tracking-[-0.1px] text-main-1">
						{getWaitlistInfoQuery.data?.data.station.name} at{" "}
						{getWaitlistInfoQuery.data?.data.station.location.name}
					</h1>
					<p className="text-sm text-[#6D748D]">
						Enter your details to join the waitlist. We will only
						partially show your name for safety reasons.
					</p>
				</div>
				<div className="space-y-4 py-9">
					<div className="space-y-1.5">
						<Label className="text-sm font-medium leading-5 tracking-[0.1%] text-main-1">
							Full Name{" "}
							<span className="text-destructive"> *</span>
						</Label>
						<Input className="h-10" {...register("full_name")} />
						{errors.full_name?.message && (
							<small className="text-sm text-destructive">
								{errors.full_name?.message as string}
							</small>
						)}
					</div>

					<div className="space-y-1.5">
						<Label className="text-sm font-medium leading-5 tracking-[0.1%] text-main-1">
							Phone Number{" "}
							<span className="text-destructive"> *</span>
						</Label>
						<div className="flex h-10 items-stretch">
							<Select
								value={countryCode}
								onValueChange={(value) => {
									setCountryCode(value);
								}}
							>
								<SelectTrigger className="h-full w-fit rounded-r-none border-r-transparent shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
									<SelectValue
										className="text-[#858C95]"
										placeholder="+1"
									/>
								</SelectTrigger>
								<SelectContent className="!z-[9999]">
									<SelectGroup>
										<SelectLabel className="px-2">
											Country Codes
										</SelectLabel>

										{countryCodes.map((option) => {
											return (
												<SelectItem
													key={option.value}
													value={option.value}
													className="px-8"
												>
													{option.label}
												</SelectItem>
											);
										})}
									</SelectGroup>
								</SelectContent>
							</Select>
							<Input
								className="h-full rounded-l-none border border-[#E4E4E7] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
								minLength={11}
								maxLength={11}
								{...register("phone_number")}
							/>
						</div>
						{errors.phone_number?.message && (
							<small className="text-sm text-destructive">
								{errors.phone_number?.message as string}
							</small>
						)}
					</div>
					<div className="space-y-1.5">
						<Label className="text-sm font-medium leading-5 tracking-[0.1%] text-main-1">
							Email
						</Label>
						<Input {...register("email")} />
						{errors.email?.message && (
							<small className="text-sm text-destructive">
								{errors.email?.message as string}
							</small>
						)}
					</div>
				</div>
				<p className="text-sm tracking-[0.07px] text-main-1">
					By confirming, you accept our{" "}
					<a
						rel="noreferrer"
						href="https://migranium.com/terms"
						className="text-primary underline"
						target="_blank"
					>
						Terms
					</a>{" "}
					and{" "}
					<a
						rel="noreferrer"
						href="https://migranium.com/privacy-policy"
						className="text-primary underline"
						target="_blank"
					>
						Policies
					</a>
					.
				</p>
				<LoaderButton
					className="mt-8 w-full"
					disabled={joinWaitlistMutation.isPending}
					loading={joinWaitlistMutation.isPending}
					type="submit"
					loaderSize={20}
				>
					Confirm
				</LoaderButton>
			</form>
		</section>
	);
};

export default UserJoinInformation;
