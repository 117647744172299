import React from "react";

interface ICustomInput {
	register: any;
	label: string;
	error?: string;
	placeholder?: string;
	required?: boolean;
	textarea?: boolean;
	input?: boolean;
	resize?: boolean;
}

const CustomInput: React.FC<ICustomInput> = ({
	label,
	error,
	placeholder,
	register,
	required,
	textarea,
	input,
	resize,
}) => {
	return (
		<div className="w-full font-hoves text-main-4">
			<div>
				<label
					className="tracker-[-0.1] text-sm font-medium leading-5"
					htmlFor={label}
				>
					{label}{" "}
					{required ? <span className="text-red-500">*</span> : ""}
				</label>
			</div>
			<div className="flex flex-col space-y-0.5">
				{input && (
					<input
						{...register}
						placeholder={placeholder}
						className={`mt-1 h-10 w-full rounded-lg border border-[#E5E5E7] px-3 outline-none ${error && error.length && "border border-red-500"}`}
					/>
				)}

				{textarea && (
					<textarea
						{...register}
						placeholder={placeholder}
						className={`mt-1 flex h-[110px] w-full flex-col items-center rounded-lg border px-4 pt-2 text-[15px] leading-5 text-main-4 outline-none md:h-[90px]  ${error && error.length ? "border border-red-500" : "border-[#E5E5E7]"} ${resize ? "resize" : "resize-none"}`}
					/>
				)}

				{error && (
					<p className="text-sm font-light text-red-500">{error}</p>
				)}
			</div>
		</div>
	);
};

export default CustomInput;
