import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { Country } from "country-state-city";
import {
	PatientWaitlistStatus,
	StatusOfPatient,
	StatusOfPatientType,
} from "./constants";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

/**
 * Formats a string input into MM/YY format for credit card expiration dates.
 * @param {Event} e - The input event object.
 * @returns {string} The formatted MM/YY string.
 */
export const formatStringToMMYY = (e: any): string => {
	const code = e.keyCode;
	const allowedKeys = [8];
	if (allowedKeys.indexOf(code) !== -1) {
		return "";
	}
	e.target.value = e.target.value
		.replace(/^([1-9]\/|[2-9])$/g, "0$1/")
		.replace(/^(0[1-9]|1[0-2])$/g, "$1/")
		.replace(/^([0-1])([3-9])$/g, "0$1/$2")
		.replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, "$1/$2")
		.replace(/^([0]+)\/|[0]+$/g, "0")
		.replace(/[^\d/]|^[/]*$/g, "")
		.replace(/\/\//g, "/");
	return e.target.value;
};

/**
 * Formats a string of numbers into a credit card number format with spaces every 4 digits.
 * @param {string} string - The input string of numbers.
 * @returns {string} The formatted credit card number string.
 */
export const formatCreditCard = (string: string) => {
	const inputVal = string.replace(/ /g, "");
	let inputNumbersOnly = inputVal.replace(/\D/g, "");
	if (inputNumbersOnly.length > 20) {
		inputNumbersOnly = inputNumbersOnly.slice(0, 20);
	}
	const splits = inputNumbersOnly.match(/.{1,4}/g);
	let spacedNumber = "";
	if (splits) {
		spacedNumber = splits.join(" ");
	}
	return spacedNumber;
};

/**
 * An array of options for schedule block durations, from 15 to 75 minutes in 15-minute increments.
 * @type {Array<{value: number, label: string}>}
 */
export const scheduleBlockOptions = Array.from({ length: 5 }).map(
	(_, index) => ({
		value: (index + 1) * 15,
		label: ((index + 1) * 15).toString(),
	})
);

/**
 * Converts a hex color code to an RGB object.
 *
 * This function takes a hex color code as a string and converts it to an RGB object.
 * The hex code can be in the short format (`#RGB`) or the full format (`#RRGGBB`).
 * If the hex code is not valid, an error is thrown.
 *
 * @param {string} hex - The hex color code to convert. It can be in `#RGB` or `#RRGGBB` format.
 * @returns {{r: number, g: number, b: number}} - An object containing the red (r), green (g), and blue (b) values as numbers.
 * @throws {Error} If the hex color code is not valid.
 */
export const hexToRGB = (hex: string) => {
	hex = hex.replace(/^#/, "");
	let r, g, b;
	if (hex.length === 3) {
		r = parseInt(hex[0] + hex[0], 16);
		g = parseInt(hex[1] + hex[1], 16);
		b = parseInt(hex[2] + hex[2], 16);
	} else if (hex.length === 6) {
		r = parseInt(hex.slice(0, 2), 16);
		g = parseInt(hex.slice(2, 4), 16);
		b = parseInt(hex.slice(4, 6), 16);
	} else {
		throw new Error("Invalid HEX color.");
	}

	return { r, g, b };
};

/**
 * Changes the theme color by setting a CSS variable with the specified color.
 *
 * If the provided theme is "default", it will be replaced with a default color (`#005893`).
 * If the theme is a valid hex color (with or without `#`), it will be converted to its RGB equivalent
 * and the CSS `--primary` variable will be updated with the RGB values.
 *
 * @param {string} theme - The hex color code or the string "default" to set the theme color.
 * @returns {string} - The theme that was actually set (either the original hex value or the default color).
 */
export const changeTheme = (theme: string): string => {
	const defaultColor = "#005893";

	// Handle the default theme case
	if (theme === "default") {
		theme = defaultColor;
	}

	// Ensure the theme is in a proper hex format
	if (!theme.startsWith("#")) {
		theme = `#${theme}`;
	}

	// Validate the hex color format using a regex
	const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
	if (!hexColorRegex.test(theme)) {
		console.warn(
			`Invalid hex color: ${theme}. Falling back to default color.`
		);
		theme = defaultColor;
	}

	// Convert hex to RGB
	const { r, g, b } = hexToRGB(theme);
	document.documentElement.style.setProperty("--primary", `${r} ${g} ${b}`);

	return theme;
};

/**
 * An array of country dialing codes.
 *
 * This array contains dialing codes for 249 countries, formatted with a "+" sign
 * followed by the country code. Each object in the array includes the dialing code
 * as both the label and the value.
 *
 * @type {Record<string, string>[]}
 */
export const countryCodes = Array.from({ length: 249 }, (_, i) => {
	return { label: "+" + (i + 1), value: "+" + (i + 1) };
});

const initialCountryOptions = Object.values(Country.getAllCountries())
	.map((country) => ({
		value: country.isoCode,
		label: country.name,
	}))
	.filter((country) => country.value !== "CA" && country.value !== "US");

/**
 * An array of country options.
 *
 * This array contains all available countries, where each object includes the
 * country's ISO code as the value and the country's name as the label.
 *
 * @type {Array<{ value: string; label: string }>}
 */
export const countryOptions = [
	{ value: "CA", label: "Canada" },
	{ value: "US", label: "United States" },
	...initialCountryOptions,
];

/**
 * Extracts the initials from a given name.
 *
 * @param {string} name - The full name from which to extract initials.
 * @param {boolean} [includeSecondNameInitial=false] - Whether to include the first letter of the second name.
 * @returns {string} The initials extracted from the name.
 *
 * @example
 * // returns 'J'
 * getInitials('John Doe');
 *
 * @example
 * // returns 'JD'
 * getInitials('John Doe', true);
 *
 * @example
 * // returns 'A'
 * getInitials('Alice');
 */
export const getInitials = (
	name: string,
	includeSecondNameInitial: boolean = false
): string => {
	const nameParts = name.trim().split(" ");

	// Extract the first letter of the first name
	let initials = nameParts[0]?.charAt(0).toUpperCase() || "";

	// If includeSecondNameInitial is true and there's a second name, add its initial
	if (includeSecondNameInitial && nameParts.length > 1) {
		initials += nameParts[1]?.charAt(0).toUpperCase() || "";
	}

	return initials;
};

/**
 * Retrieves the corresponding key from `StatusOfPatient` based on the provided status.
 *
 * This function searches through the keys of `StatusOfPatient` to find the key whose value matches
 * the provided `status`. The matching key is then used to look up the colors in `PatientWaitlistStatus`.
 *
 * @param {string} status - The current status of the patient from the waitlist.
 * @returns {keyof typeof PatientWaitlistStatus} - The key from `PatientWaitlistStatus` that corresponds to the provided status.
 */
export const patientStatusKey: (
	status: StatusOfPatientType
) => keyof typeof PatientWaitlistStatus = (status) =>
	Object.keys(StatusOfPatient).find(
		(key) => StatusOfPatient[key as keyof typeof StatusOfPatient] === status
	) as keyof typeof PatientWaitlistStatus;
