import React from "react";
import { IWaitListSubTextProps } from "../../types/waitlist";

const WaitListSubText: React.FC<IWaitListSubTextProps> = ({
	imageUrl,
	content,
	className = "leading-8 lg:text-xl text-sm lg:font-semibold font-bold text-main-4 -tracking-1%",
}) => {
	return (
		<div className="flex items-center gap-2 text-start md:mt-2 md:items-start lg:gap-4">
			<img
				className="h-5 w-4 whitespace-nowrap md:h-8 md:w-6"
				src={imageUrl}
				alt="icons"
			/>
			<p className={className}>{content}</p>
		</div>
	);
};

export default WaitListSubText;
