import React from "react";
import { Button } from "../../components/ui/button";
import { Link } from "react-router-dom";
import RateExperience from "../../components/Waitlist/RateExperience";
import useUserStore from "@/src/store/useUserStore";

const DoneWaitlist: React.FC = () => {
	const user = useUserStore((s) => s.user);

	return (
		<section className="flex flex-col items-stretch space-y-3 px-4 py-8">
			<img
				src="/assets/rating-banner.svg"
				alt="Rating Banner"
				className="mx-auto max-w-[118px]"
			/>
			<div className="flex flex-col items-stretch space-y-3 rounded-xl bg-white p-6">
				<div className="space-y-2">
					<h1 className="text-[28px] font-bold leading-[140%] tracking-[-0.1.4px] text-main-1">
						You&apos;re all Done!
					</h1>
					<p className="text-sm tracking-[0.07px] text-main-7">
						Thank you for visiting{" "}
						{user?.waitlist_info.business_name}.
					</p>
				</div>
			</div>
			<RateExperience />
		</section>
	);
};

export default DoneWaitlist;
