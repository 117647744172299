import React, { useState } from "react";
import { LuStar } from "react-icons/lu";

interface StarRatingProps {
	onRatingChange?: (rating: number) => void;
}

const StarRating: React.FC<StarRatingProps> = ({ onRatingChange }) => {
	const [rating, setRating] = useState(0);
	const [hover, setHover] = useState(0);

	const handleClick = (index: number) => {
		setRating(index);
		if (onRatingChange) {
			onRatingChange(index);
		}
	};

	return (
		<div className="flex items-center justify-center space-x-[18px]">
			{[1, 2, 3, 4, 5].map((index) => (
				<LuStar
					key={index}
					size={32}
					onClick={() => handleClick(index)}
					onMouseEnter={() => setHover(index)}
					onMouseLeave={() => setHover(0)}
					color={(hover || rating) >= index ? "#FFB33E" : "#6D748D"}
					fill={(hover || rating) >= index ? "#FFB33E" : "none"}
					className="cursor-pointer transition-colors duration-200"
				/>
			))}
		</div>
	);
};

export default StarRating;
