import { Button } from "@/src/components/ui/button";
import {
	JoinWorkingHour,
	JoinWorkingHoursErrorResponse,
} from "@/src/types/waitlist";
import { formatTimeFrom24HourToMeridian } from "@/src/utils/date";
import { AxiosError } from "axios";
import React from "react";

const ClosedWaitlist: React.FC<{
	query: AxiosError<JoinWorkingHoursErrorResponse>;
}> = ({ query }) => {
	// Extract working_hours from the error response (if present)
	const workingHours = query?.response?.data?.working_hours;

	// Helper to merge time slots for the same day
	const mergeWorkingHours = (hours) => {
		const merged = {};

		hours.forEach((hour) => {
			if (merged[hour.day_of_week]) {
				merged[hour.day_of_week].time_slots = [
					...merged[hour.day_of_week].time_slots,
					...hour.time_slots,
				];
			} else {
				merged[hour.day_of_week] = { ...hour };
			}
		});

		return Object.values(merged) as JoinWorkingHour[];
	};

	// Merge possible duplicate working hours by day_of_week
	const mergedWorkingHours = mergeWorkingHours(workingHours);

	// Sort the merged working hours from Monday to Sunday
	const daysOfWeekOrder = [
		"monday",
		"tuesday",
		"wednesday",
		"thursday",
		"friday",
		"saturday",
		"sunday",
	];

	// Sort the workingHours array based on the day_of_week
	const sortedWorkingHours = mergedWorkingHours.sort(
		(a, b) =>
			daysOfWeekOrder.indexOf(a.day_of_week.toLowerCase()) -
			daysOfWeekOrder.indexOf(b.day_of_week.toLowerCase())
	);

	return (
		<section className="mx-4 my-auto flex flex-col items-center space-y-3 rounded-xl bg-white p-12">
			<img
				src="/assets/icons/closed-waitlist.svg"
				alt="Closed waitlist"
				className="max-w-[105px] pb-12"
			/>
			<div className="flex flex-col space-y-6">
				<div className="max-w-[310px] space-y-1">
					<h1 className="text-center text-xl font-semibold tracking-[-1%] text-main-1">
						Sorry, we&apos;re currently closed!
					</h1>
					<p className="text-center text-sm tracking-[-0.5%] text-main-7">
						Please try again during our operational hours listed
						below or book an appointment.
					</p>
				</div>
				<table className="min-w-full bg-white" border={0}>
					<tbody>
						{sortedWorkingHours.map((item, index) => (
							<tr key={index} className="">
								<td className="trackign-[-0.5%] text-end text-sm font-medium text-main-7">
									{/* Day of the week */}
									{item.day_of_week.charAt(0).toUpperCase() +
										item.day_of_week.slice(1)}
								</td>
								<td className="trackign-[-0.5%] py-1 pl-3 text-start text-sm text-main-7">
									{/* Check if there are active time slots or mark as Closed */}
									{item.time_slots.length > 0 &&
									item.is_active ? (
										item.time_slots.map((slot, idx) => (
											<div key={idx} className="">
												{`${formatTimeFrom24HourToMeridian(
													slot.start_time
												)} - ${formatTimeFrom24HourToMeridian(
													slot.end_time
												)}`}
											</div>
										))
									) : (
										<span>Closed</span>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<Button variant="ghost" className="mx-auto text-primary">
				Book Appointment
			</Button>
		</section>
	);
};

export default ClosedWaitlist;
