import React from "react";
import ratingImage from "@/public/assets/appointment-rescheldule.svg";
import hospitalIcon from "@/public/assets/hospital.svg";
import calendarIcon from "@/public/assets/calendar_sm.svg";
import locationIcon from "@/public/assets/location.svg";
import { Link } from "react-router-dom";
import WaitListLayout from "../../components/Waitlist/WaitListLayout";
import WaitListSubText from "../../components/Waitlist/WaitListSubText";
import { Button } from "@/src/components/ui/button";

const ReschelduleAppointment: React.FC = () => {
	return (
		<>
			<div className="bg-primary-3 h-screen overflow-hidden py-4 font-hoves md:py-0">
				<WaitListLayout
					hasFooterButton={false}
					imageStyle="md:w-full w-[60%]"
					imageUrl={ratingImage}
				>
					<div className="rounded-12 h-auto bg-white px-6 pb-5 pt-6 text-main-4 md:mt-0">
						<h1 className="text-[28px] font-semibold leading-[39.5px] tracking-0.5% md:leading-[38px] md:-tracking-1.5%">
							Congratulations!
						</h1>
						<p className="mt-1 text-sm leading-[22.4px] tracking-0.5% text-main-5 md:text-base">
							Appointment request sent successfully.
						</p>
					</div>
					<div className="rounded-12 mb-20 mt-2 h-auto bg-white px-6 pb-5 pt-6 text-main-4 md:mb-0 md:mt-6">
						<h1 className="text-[28px] font-semibold leading-[39.2px] -tracking-1.5% md:leading-[38px]">
							Appointment Details
						</h1>
						<p className="mb-5 mt-1 text-sm leading-[22.4px] tracking-0.5% text-main-5 md:text-base">
							Check your{" "}
							<Link
								className="border-b border-main-1 text-main-1"
								to=""
							>
								email
							</Link>{" "}
							for confirmation.
						</p>
						<WaitListSubText
							imageUrl={calendarIcon}
							content="December 12, 2023 at 02:30 pm"
						/>
						<WaitListSubText
							imageUrl={hospitalIcon}
							content="North York City Center Medical Clinic"
						/>
						<WaitListSubText
							className="text-sm font-normal leading-[18px] -tracking-1% text-main-5"
							imageUrl={locationIcon}
							content="5 Park Home Ave #130, Toronto, ON M2N 6L4, Toronto, Ontario, Canada"
						/>
						<div className="mt-5 flex justify-end space-x-4">
							<Button
								className="w-auto bg-transparent px-3 text-sm !text-[#C8322B]"
								disabled={false}
								onClick={() => {}}
								type="button"
								name="Cancel Appointment"
							/>
							<Button
								className="w-auto px-3 text-sm"
								disabled={false}
								onClick={() => {}}
								type="button"
								name="Reschedule"
							/>
						</div>
					</div>
				</WaitListLayout>
			</div>
		</>
	);
};

export default ReschelduleAppointment;
