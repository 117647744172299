import React, { useState } from "react";
import Day from "./Day";
import dayjs from "dayjs";

const Month: React.FC<{ month: dayjs.Dayjs[][] }> = ({ month }) => {
	const [currentDay, setCurrentDay] = useState("");

	console.log(currentDay);

	return (
		<div>
			<div className="mb-2 flex justify-between pl-6 pt-6 text-sm font-medium text-main-4">
				<p>Mo</p>
				<p>Tu</p>
				<p>We</p>
				<p>Th</p>
				<p>Fr</p>
				<p>Sat</p>
				<p className="pr-9">Su</p>
			</div>
			<div className="grid flex-1 grid-cols-7 grid-rows-5 pr-10 text-sm text-main-4 md:pr-4">
				{month.map((row, i: number) => (
					<React.Fragment key={i}>
						{row.map((day, idx: number) => (
							<Day
								handleDay={() => setCurrentDay(day.format("D"))}
								day={day}
								key={idx}
								rowIdx={i}
							/>
						))}
					</React.Fragment>
				))}
			</div>
		</div>
	);
};

export default Month;
