import React from "react";
import welcomeImage from "@/public/assets/appointment-icon.svg";
import WaitListLayout from "../../components/Waitlist/WaitListLayout";
import { Link } from "react-router-dom";
import CustomInput from "../../components/Waitlist/CustomInput";
import { Button } from "@/src/components/ui/button";

const AppointmentDetails: React.FC = () => {
	return (
		<>
			<div className="bg-primary-3 h-auto overflow-hidden py-24 font-hoves md:h-screen md:py-0">
				<div className="fixed left-0 top-0 flex w-full justify-between bg-white font-hoves md:relative">
					<div className="ml-6 flex flex-1 items-center border-b-2 border-[#053969] py-5 md:ml-24">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M13.9998 6L8.70696 11.2929C8.31643 11.6834 8.31643 12.3166 8.70696 12.7071L13.9998 18"
								stroke="#858C95"
								strokeWidth="2"
								strokeLinecap="round"
							/>
						</svg>
						<p className="flex w-full justify-center text-center text-[15px] font-semibold text-main-7 md:text-lg">
							Detail
						</p>
					</div>
					<div className="mr-6 flex flex-1 items-center border-b-2 border-[#E5E5E7] py-5 md:mr-24">
						<p className="flex w-full justify-center text-center text-[15px] text-main-4 md:text-lg">
							Time
						</p>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M18 6L6 18M18 18L6 6.00001"
								stroke="#858C95"
								strokeWidth="2"
								strokeLinecap="round"
							/>
						</svg>
					</div>
					{/* <div className='flex-1'>Details</div> */}
				</div>
				<WaitListLayout
					handleClick={() => {}}
					name="Confirm"
					href={""}
					imageStyle="lg:block hidden flex w-[70%] -mt-10"
					imageUrl={welcomeImage}
				>
					<div className="rounded-12 -mt-20 h-auto bg-white px-6 pb-4 pt-10 text-main-4 lg:py-4">
						<h1 className="text-xl font-semibold leading-5 -tracking-1.5% md:text-2xl lg:text-[28px] lg:leading-[38px]">
							North York City Center Medical Clinic
						</h1>
						<p className="mt-7 text-[22px] text-lg font-semibold -tracking-1% md:mt-3 lg:leading-[30px]">
							Add your details
						</p>
						<p className="mt-2 text-sm leading-5 tracking-0.5% text-main-5 lg:text-base lg:leading-[22.4px]">
							Only an abbreviation of your first name will be
							shown on the waitlist for privacy.
						</p>
						<form>
							<div className="mt-8 lg:mt-4">
								<CustomInput
									label="Full Name"
									register={undefined}
									input
									required
								/>
							</div>
							<div className="mt-2.5">
								<CustomInput
									label="Phone Number"
									register={undefined}
									input
									required
								/>
							</div>
							<div className="mt-2.5">
								<CustomInput
									label="Email Address"
									register={undefined}
									input
								/>
							</div>
							<p className="ld:text-base mb-8 mt-12 font-inter text-sm leading-[22.4px] tracking-0.5% text-main-4 md:mt-8">
								By confirming, you accept our{" "}
								<a
									className="border-b border-main-1 text-main-1"
									href=""
								>
									Terms
								</a>{" "}
								and{" "}
								<a
									className="border-b border-main-1 text-main-1"
									href=""
								>
									Policies
								</a>
								.
							</p>
							<Link to={""}>
								<Button
									className="hidden w-full md:block"
									type="button"
									name="Confirm"
								/>
							</Link>
						</form>
					</div>
				</WaitListLayout>
			</div>
		</>
	);
};

export default AppointmentDetails;
