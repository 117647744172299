import { startOfMonth, startOfToday } from "date-fns";
import dayjs from "dayjs";

export const getMonth = (month = dayjs().month()) => {
	const year = dayjs().year();
	const firstDayOfTheMonth = dayjs(new Date(year, month, -1)).day();
	let currentMonthCount = 6 - firstDayOfTheMonth;
	const dayMatrix = new Array(5).fill([]).map(() => {
		return new Array(7).fill(null).map(() => {
			currentMonthCount++;
			return dayjs(new Date(year, month, currentMonthCount));
		});
	});

	return dayMatrix;
};

/**
 * Returns the start date of the current month.
 *
 * @returns {Date} The start of the current month.
 *
 * @example
 * // Assuming today's date is July 19, 2024
 * const startOfMonth = getStartOfCurrentMonth();
 * console.log(startOfMonth);
 * // Output: Mon Jul 01 2024 00:00:00 GMT+0000 (Coordinated Universal Time)
 */
export const getStartOfCurrentMonth = () => {
	return startOfMonth(startOfToday());
};

/**
 * Converts a time from 24-hour format to 12-hour format with AM/PM.
 *
 * @param {string} time - The time in 24-hour format (e.g., "13:00:00").
 * @returns {string} - The time in 12-hour format with AM/PM (e.g., "1:00 PM").
 *
 * @example
 * formatTimeFrom24HourToMeridian("13:00:00"); // Returns "1:00 PM"
 * formatTimeFrom24HourToMeridian("09:30:00"); // Returns "9:30 AM"
 */
export const formatTimeFrom24HourToMeridian = (time: string): string => {
	const [hour, minute] = time.split(":");
	let hourNum = parseInt(hour);
	const ampm = hourNum >= 12 ? "PM" : "AM";
	hourNum = hourNum % 12 || 12; // Convert 24-hour to 12-hour, handle 0 as 12
	return `${hourNum}:${minute} ${ampm}`;
};
