import { Button } from "@/src/components/ui/button";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/src/components/ui/dialog";
import { useUpdateWaitlist } from "@/src/store/slices/waitlist";
import useUserStore from "@/src/store/useUserStore";
import {
	UpdateWaitlistActionType,
	UpdateWaitlistSchema,
} from "@/src/types/waitlist";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import useCustomToast from "../CustomToast";
import { LoaderButton } from "../ui-extended/loader-button";
import { Label } from "../ui/label";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../ui/select";
import { Textarea } from "../ui/textarea";

const RequestSwap: React.FC = () => {
	const user = useUserStore((s) => s.user);
	const [isOpen, setIsOpen] = React.useState(false);
	const customToast = useCustomToast();
	const queryClient = useQueryClient();
	const updateWaitlistMutation = useUpdateWaitlist(
		() => {
			customToast("Your request has been sent. 🫡", {
				id: "update-waitlist",
			});
			queryClient.invalidateQueries({ queryKey: ["waitlist-info"] });
			setIsOpen(false);
			reset();
		},
		() => {
			customToast("Request for swap could not be made 🤕 ", {
				id: "update-waitlist",
				type: "error",
			});
			queryClient.invalidateQueries({ queryKey: ["waitlist-info"] });
		}
	);

	const {
		register,
		handleSubmit,
		// setError
		watch,
		setValue,
		formState: { errors },
		reset,
	} = useForm<UpdateWaitlistActionType>({
		defaultValues: { action: "request_swap" },
		resolver: zodResolver(UpdateWaitlistSchema),
	});

	const onSubmit: SubmitHandler<UpdateWaitlistActionType> = (data) => {
		updateWaitlistMutation.mutate(data);
	};

	return (
		<Dialog
			open={isOpen}
			onOpenChange={(state) => {
				if (!updateWaitlistMutation.isPending) {
					setIsOpen(state);
					if (!state) reset();
				}
			}}
		>
			<DialogTrigger>
				<button className="mt-6 w-fit p-0 text-xs text-primary underline shadow-none">
					Swap Position
				</button>
			</DialogTrigger>

			<DialogContent className="max-w-[360px] gap-8 rounded-[10px] p-6">
				<form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
					<div className="flex flex-col space-y-2">
						<DialogHeader className="items-stretch">
							<DialogTitle className="flex items-center justify-between">
								<h1 className="text-[22px] font-semibold leading-[30px] tracking-[-0.22px]">
									Position Swap Request
								</h1>
								<DialogClose>
									<i className="mgc_close_line before:!text-main-4" />
								</DialogClose>
							</DialogTitle>
							<p className="text-left text-base tracking-[-0.16px] text-main-7">
								You may request to swap your position in the
								waitlist.
							</p>
						</DialogHeader>
					</div>
					<div className="space-y-4">
						<div className="space-y-1.5">
							<Label className="text-main-1">
								Requested Position{" "}
								<span className="text-destructive">*</span>
							</Label>
							<Select
								value={watch("swap_position")?.toString()}
								onValueChange={(value) => {
									setValue("swap_position", parseInt(value));
								}}
							>
								<SelectTrigger className="">
									<SelectValue
										className="text-[#858C95]"
										placeholder="New Position"
									/>
								</SelectTrigger>
								<SelectContent>
									<SelectGroup>
										{Array.from({
											length: user?.total_customers ?? 1,
										}).map((_, idx) => {
											if (idx + 1 === user?.position)
												return;
											return (
												<SelectItem
													key={idx}
													value={(idx + 1).toString()}
													className="px-8"
												>
													{idx + 1}
												</SelectItem>
											);
										})}
									</SelectGroup>
								</SelectContent>
							</Select>
							{errors.swap_position?.message && (
								<small className="text-sm text-destructive">
									{errors.swap_position?.message as string}
								</small>
							)}
						</div>
						<div className="space-y-1.5">
							<Label className="text-main-1">Add Reason</Label>
							<Textarea
								className="min-h-[134px] resize-none"
								{...register("swap_reason")}
							/>
						</div>
					</div>
					<DialogFooter className="flex w-full !flex-row space-x-2.5">
						<Button
							className="flex-1"
							variant="ghost"
							onClick={() => {
								if (!updateWaitlistMutation.isPending) {
									setIsOpen(false);
									reset();
								}
							}}
							disabled={updateWaitlistMutation.isPending}
						>
							Cancel
						</Button>
						<LoaderButton
							className="flex-1 text-white"
							type="submit"
							loaderSize={20}
							onClick={() => handleSubmit(onSubmit)}
							loading={updateWaitlistMutation.isPending}
							disabled={updateWaitlistMutation.isPending}
						>
							Send Request
						</LoaderButton>
					</DialogFooter>
				</form>
			</DialogContent>
		</Dialog>
	);
};

export default RequestSwap;
