import React from "react";
import { IWaitListLayoutProps } from "../../types/waitlist";
import Logo from "../Logo";
import { LoaderButton } from "../ui-extended/loader-button";

const WaitListLayout: React.FC<IWaitListLayoutProps> = ({
	imageUrl,
	children,
	hasFooterText = true,
	hasFooterButton = true,
	handleClick,
	loaderSize,
	name,
	isButtonDisabled = false,
	isSubmitting,
	imageStyle = "w-full h-[247px] md:h-full",
}) => {
	return (
		<main className="h-full w-full overflow-hidden">
			<div className="h-full items-center justify-between px-4 md:flex md:space-x-20 lg:space-x-64 lg:px-24">
				<div className="flex flex-1 justify-center md:h-full">
					<img className={imageStyle} src={imageUrl} alt="" />
				</div>
				<div className="flex-1">{children}</div>
			</div>
			{hasFooterText && (
				<div className="bg-primary-3 fixed bottom-0 z-50 w-full p-4 font-hoves md:absolute md:bottom-2">
					{hasFooterButton === true && (
						<LoaderButton
							className="max-h-[32px] w-full md:hidden"
							onClick={handleClick}
							type="submit"
							disabled={isButtonDisabled}
							name={name}
							loaderSize={loaderSize}
							loading={isSubmitting}
						/>
					)}
					<div className="mt-3 flex items-center justify-center">
						<p className="text-xs leading-5 text-main-5">
							Powered by
						</p>
						<Logo
							width="w-[82.47px]"
							height="h-4"
							color="#043B6D"
						/>
					</div>
				</div>
			)}
		</main>
	);
};

export default WaitListLayout;
