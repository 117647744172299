import React, { useEffect, useState } from "react";
import { getMonth } from "../../utils/date";
import hospitalIcon from "@/public/assets/hospital.svg";
import locationIcon from "@/public/assets/location.svg";
import previousDate from "@/public/assets/date-previous.svg";
import nextDate from "@/public/assets/date-next.svg";
import Month from "./Month";
import dayjs from "dayjs";
import WaitListSubText from "../Waitlist/WaitListSubText";

const DateCalendar: React.FC = () => {
	const [months, setMonths] = useState<dayjs.Dayjs[][]>(getMonth());
	const [monthIndex, setMonthIndex] = useState<number>(0);

	console.log(months);

	useEffect(() => {
		setMonths(getMonth(monthIndex));
	}, [monthIndex]);

	const handlePreviousBtn = () => {
		setMonthIndex(monthIndex - 1);
	};

	const handleNextBtn = () => {
		setMonthIndex(monthIndex + 1);
		console.log(monthIndex + 1);
	};

	return (
		<div className="md:w-8/12">
			<div className="rounded-12 h-auto pb-6 pt-4">
				<div className="rounded-12 border-primary-3 bg-white px-6 pb-6 pt-4">
					<WaitListSubText
						imageUrl={hospitalIcon}
						content="North York City Center Medical Clinic"
					/>
					<WaitListSubText
						className="text-sm font-normal leading-[18px] -tracking-1% text-main-5 md:text-xl"
						imageUrl={locationIcon}
						content="5 Park Home Ave #130, Toronto, ON M2N 6L4, Toronto, Ontario, Canada"
					/>
				</div>
				<div className="rounded-12 border-primary-3 my-3 bg-white px-6 pb-5 pt-4">
					<p className="text-sm font-medium leading-5 tracking-[0.1%] text-main-4">
						Select Appointment Type
					</p>
					<div>
						{/* <label className='text-sm font-medium leading-5 text-main-4 tracker-[-0.1]'>Expected Position *</label> */}
						<div className="mt-1 flex h-10 items-center justify-between rounded-lg  border border-[#E5E5E7] px-4">
							<select className="h-full w-full appearance-none text-sm text-main-4 outline-none">
								<option>First Time</option>
								<option>Regular</option>
								<option>Follow up</option>
							</select>
							<span className=" ">
								<svg
									width="11"
									height="7"
									viewBox="0 0 11 7"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M9.83337 1.5L5.8637 5.46967C5.57081 5.76256 5.09594 5.76256 4.80304 5.46967L0.833374 1.5"
										stroke="#323539"
										strokeWidth="1.67"
										strokeLinecap="round"
									/>
								</svg>
							</span>
						</div>
					</div>
				</div>
				<div className="rounded-12 h-auto border border-[#E5E5E7] bg-white pb-8 pt-4">
					<p className="px-6 text-base font-medium leading-[30px] -tracking-1% text-main-4 md:text-[22px]">
						Select your booking date
					</p>
					<div className="mt-6 flex items-center justify-between px-6">
						<img
							onClick={handlePreviousBtn}
							role="button"
							src={previousDate}
							alt="next icon"
						/>
						<p className="text-base font-medium leading-6 -tracking-1% text-main-4">
							{/* {currentMonthNumber} */}
							{dayjs(new Date(dayjs().year(), monthIndex)).format(
								"MMMM YYYY"
							)}
						</p>
						<img
							onClick={handleNextBtn}
							role="button"
							src={nextDate}
							alt="next icon"
						/>
					</div>
					<Month month={months} />
				</div>
			</div>
		</div>
	);
};

export default DateCalendar;
