import {
	JoinWaitlistResponse,
	JoinWaitlistType,
	UpdateWaitlistActionType,
} from "@/src/types/waitlist";
import $http from "../xhr";

export const APIVersion2GetWaitlistInfo = async (data: {
	waitlist_code?: string | null;
}): Promise<any> =>
	$http
		.get(`v2/customer/waitlist/status/${data.waitlist_code}`)
		.then((res) => res.data);

export const APIVersion2GetJoinInfo = async (data: {
	join_code?: string;
}): Promise<JoinWaitlistResponse> =>
	$http
		.get(`v2/customer/waitlist/join/${data.join_code}/info`)
		.then((res) => res.data);

export const APIVersion1JoinWaitlist = async (
	data: JoinWaitlistType & { join_code?: string }
): Promise<any> =>
	$http
		.post(`v2/customer/waitlist/join/${data.join_code}`, {
			...data,
			join_code: undefined,
		})
		.then((res) => res.data);

export const APIVersion1UpdateWaitlist = async (
	data: UpdateWaitlistActionType & { waitlist_code?: string }
): Promise<any> =>
	$http
		.post(`v2/customer/waitlist/update/${data.waitlist_code}`, {
			...data,
			waitlist_code: undefined,
		})
		.then((res) => res.data);
