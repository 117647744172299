import React from "react";
import Logo from "../../components/Logo";
import DateCalendar from "../../components/DateCalendar";
import { AVAILABLE_APPOINTMENT_TIME } from "../../utils/constants";
import { Button } from "@/src/components/ui/button";

const BookTime: React.FC = () => {
	return (
		<div className="rounded-12 mt-5 h-auto w-full bg-white px-8 py-6 font-hoves lg:-mt-16">
			<p className="text-base font-medium leading-[30px] -tracking-1% text-main-4 md:text-[22px]">
				Select your booking Time
			</p>
			<div className="mt-4 grid grid-cols-3 gap-4 md:grid-cols-7">
				{AVAILABLE_APPOINTMENT_TIME.map((time: any, index: number) => (
					<button
						key={index}
						className="duration-400 border-secondary-5 text-secondary-5 hover:bg-secondary-5 rounded-lg border-[0.5px] bg-white py-2 text-base leading-5 transition-all delay-100 ease-in-out hover:text-white"
						type="button"
					>
						{time}
					</button>
				))}
			</div>
		</div>
	);
};

const AppointmentSchelduleTime: React.FC = () => {
	return (
		<div>
			<div className="fixed left-0 top-0 flex w-full justify-between bg-white font-hoves">
				<div className="ml-6 flex flex-1 items-center border-b-2 border-[#053969] py-5 lg:ml-24">
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M13.9998 6L8.70696 11.2929C8.31643 11.6834 8.31643 12.3166 8.70696 12.7071L13.9998 18"
							stroke="#858C95"
							strokeWidth="2"
							strokeLinecap="round"
						/>
					</svg>
					<p className="flex w-full justify-center text-center text-[15px] font-semibold text-main-7 md:text-lg">
						Detail
					</p>
				</div>
				<div className="flex flex-1 items-center border-b-2 border-[#053969] py-5 pr-6 lg:mr-24">
					<p className="flex w-full justify-center text-center text-[15px] text-main-4 md:text-lg">
						Time
					</p>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M18 6L6 18M18 18L6 6.00001"
							stroke="#858C95"
							strokeWidth="2"
							strokeLinecap="round"
						/>
					</svg>
				</div>
				{/* <div className='flex-1'>Details</div> */}
			</div>
			<div className="bg-primary-3 px-6 py-24 pb-24 font-hoves md:h-screen md:overflow-hidden lg:h-auto lg:px-24 2xl:h-screen">
				<div>
					<div className="h-full w-full items-center justify-center gap-6 align-top md:flex">
						<DateCalendar />
						<div className="w-full">
							<BookTime />
							<div className="hidden justify-center md:flex">
								<Button
									className="mt-6 w-auto px-6 py-1.5"
									name="Continue"
									type="button"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="bg-primary-3 fixed bottom-0 z-50 flex w-full flex-col items-center justify-center font-hoves">
				<div className="block w-full justify-center px-6 md:hidden">
					<Button
						className="mt-6 w-full px-6 py-1.5"
						name="Continue"
						type="button"
					/>
				</div>
				<div className="flex items-center py-4">
					<p className="text-xs leading-5 text-main-5">Powered by</p>
					<Logo width="w-[82.47px]" height="h-4" color="#043B6D" />
				</div>
			</div>
		</div>
	);
};

export default AppointmentSchelduleTime;
