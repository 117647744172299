import React from "react";
import { Outlet } from "react-router";
import logoBlueFooter from "@/public/assets/brand/logo-blue-footer.svg";

const Layout: React.FC = () => {
	return (
		<main className="relative flex min-h-[100dvh] flex-col justify-between bg-[#F3F3F3]">
			<Outlet />
			<footer className="sticky bottom-0 flex items-center justify-center space-x-2 bg-[#F3F3F3] py-2">
				<small className="py-0.5 text-[12px] text-[#6D748D]">
					Powered by
				</small>
				<img
					src={logoBlueFooter}
					alt="Migranium logo"
					className="py-0.5"
				/>
			</footer>
		</main>
	);
};

export default Layout;
