export const API_URI = import.meta.env.VITE_BASE_URL;

export type ObjectValues<T> = T[keyof T];

export const AVAILABLE_APPOINTMENT_TIME = [
	"09:00",
	"09:08",
	"09:16",
	"09:24",
	"09:32",
	"09:40",
	"09:48",
	"09:56",
	"10:04",
	"10:12",
	"10:20",
	"10:28",
	"09:40",
	"09:48",
	"09:00",
	"09:08",
	"09:16",
	"09:24",
	"09:32",
	"09:40",
	"09:48",
	"09:00",
	"09:08",
	"09:16",
	"09:24",
	"09:32",
	"09:40",
	"09:48",
	"09:00",
	"09:08",
	"09:16",
	"09:24",
	"09:32",
	"09:40",
	"09:48",
	"09:00",
	"09:08",
	"09:16",
	"09:24",
	"09:32",
	"09:40",
	"09:48",
	"09:00",
	"09:08",
];

export const PatientWaitlistStatus = {
	Pending: {
		textColor: "#B7241D",
		backgroundColor: "#F4E2EC",
	},
	Confirmed: {
		textColor: "#053969",
		backgroundColor: "#ABDBF7",
	},
	NextInLine: {
		textColor: "#896200",
		backgroundColor: "#F7EDD4",
	},
	InService: {
		textColor: "#086E17",
		backgroundColor: "#B5EAC8",
	},
};

export const StatusOfPatient = {
	AllStatus: "all_status",
	Pending: "waiting_to_be_approved",
	Confirmed: "accepted",
	NextInLine: "next_to_serve",
	InService: "getting_service",
	Done: "done",
	Cancelled: "cancelled",
} as const;

export type StatusOfPatientType = ObjectValues<typeof StatusOfPatient>;
