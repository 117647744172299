import * as Sentry from "@sentry/react";
import "./styles/index.css";
import "mingcute_icon/font/Mingcute.css";
import React from "react";
import ReactDOM from "react-dom/client";
import QueryClientConfig from "./configs/QueryClientConfig.tsx";
import Routes from "./pages/Routes.tsx";

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: [
		"localhost",
		/^https:\/\/migranium\.com\/api/,
		/^https:\/\/migranium\.com/,
	],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
	<React.StrictMode>
		<QueryClientConfig>
			<Routes />
		</QueryClientConfig>
	</React.StrictMode>
);
