import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";

interface DayProps {
	day: Dayjs;
	rowIdx: number;
	handleDay: () => void;
}

const Day: React.FC<DayProps> = ({ day, handleDay }) => {
	const [selectedDay, setSelectedDay] = useState<dayjs.Dayjs | null>(null);

	const getCurrentDayClass = () => {
		if (selectedDay && day.isSame(selectedDay, "day")) {
			return "rounded-lg bg-main-1 w-7 h-7 flex justify-center items-center text-white underline";
		} else if (day.isSame(dayjs(), "day")) {
			return "rounded-lg w-7 h-7 flex justify-center items-center text-main-4";
		} else {
			return "text-main-4 w-7 h-7 flex justify-center items-center";
		}
	};

	const handleClick = () => {
		setSelectedDay((prevSelectedDay) => {
			if (prevSelectedDay && prevSelectedDay.isSame(day, "day")) {
				// Deselect the currently selected day
				return null;
			} else {
				// Toggle the selection of the clicked day
				return day;
			}
		});
		handleDay();
	};

	return (
		<div className="flex flex-col">
			<div
				onClick={handleClick}
				className="flex cursor-pointer items-center justify-center text-sm"
			>
				<p
					className={`mt-5 flex justify-center text-sm font-medium ${getCurrentDayClass()}`}
				>
					{day.format("D")}
				</p>
			</div>
		</div>
	);
};

export default Day;
