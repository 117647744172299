import React from "react";
import welcomeImage from "@/public/assets/appointment-icon.svg";
import hospitalIcon from "@/public/assets/hospital.svg";
import locationIcon from "@/public/assets/location.svg";
import { Link } from "react-router-dom";
import WaitListLayout from "../../components/Waitlist/WaitListLayout";
import WaitListSubText from "../../components/Waitlist/WaitListSubText";
import { Button } from "@/src/components/ui/button";

const AppointmentWelcomeCard: React.FC = () => {
	return (
		<div className="rounded-12 mt-14 h-auto bg-white px-6 py-4 md:mt-0">
			<h1 className="mb-3 text-[28px] font-semibold text-main-4 md:mb-0 lg:text-32px">
				Welcome
			</h1>
			<WaitListSubText
				imageUrl={hospitalIcon}
				content="North York City Center Medical Clinic"
			/>
			<WaitListSubText
				className="text-sm font-normal leading-5 tracking-0.5% text-main-5 md:leading-8 md:-tracking-1% lg:text-xl"
				imageUrl={locationIcon}
				content="5 Park Home Ave #130, Toronto, ON M2N 6L4, Toronto, Ontario, Canada"
			/>
			<div className="mt-10">
				<Link to={"/"}>
					<Button type="button" name="Schedule Appointment" />
				</Link>
			</div>
		</div>
	);
};

const ScheduleAppointment: React.FC = () => {
	return (
		<div className="bg-primary-3 h-screen overflow-hidden py-10 font-hoves md:pb-0">
			<WaitListLayout
				hasFooterButton={false}
				imageStyle="md:w-[70%] w-[50%]"
				imageUrl={welcomeImage}
			>
				<AppointmentWelcomeCard />
			</WaitListLayout>
		</div>
	);
};

export default ScheduleAppointment;
