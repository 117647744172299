import useUserStore from "@/src/store/useUserStore";
import {
	PatientWaitlistStatus,
	StatusOfPatientType,
} from "@/src/utils/constants";
import { patientStatusKey } from "@/src/utils/general";
import { Badge } from "../ui/badge";
import CheckInModal from "./CheckInWaitlistModal";
import LeaveWaitlistModal from "./LeaveWaitlistModal";
import RequestSwap from "./RequestSwap";

const ViewWaitListDetails = () => {
	const user = useUserStore((s) => s.user);

	const estimatedWaitTime = {
		hour: Math.floor(parseInt(user?.estimated_wait_time ?? "") / 60),
		minute: parseInt(user?.estimated_wait_time ?? "") % 60,
	};

	const { textColor, backgroundColor } = PatientWaitlistStatus[
		patientStatusKey(
			user?.waitlist_info.status ?? ("confirmed" as StatusOfPatientType)
		)
	] || {
		textColor: "#000000",
		backgroundColor: "#FFFFFF",
	};

	return (
		<div className="mt-5 flex flex-col">
			<div className="flex items-center justify-between space-x-2 px-6">
				<i className="mgc_group_2_line before:!text-primary" />
				<div className="flex flex-1 items-center justify-between">
					<p className="capitalize">
						{user?.waitlist_info?.customer?.full_name}
					</p>
					<Badge
						style={{
							color: textColor,
							backgroundColor,
						}}
						className="rounded-md px-1.5 py-0 text-xxs font-medium leading-[22px] tracking-[-0.1px]"
					>
						Confirmed
					</Badge>
				</div>
			</div>
			<div className="mt-4 flex flex-col items-center px-6 pb-8">
				<div className="mx-auto flex flex-col items-center">
					<h3 className="p-2.5 text-sm font-bold text-[#6D748D]">
						My Position
					</h3>
					<h1 className="text-[100px] font-[800] leading-none text-primary">
						{user?.waitlist_info?.position}
					</h1>
				</div>
				<RequestSwap />
				<div className="mt-8 flex w-full items-center justify-between rounded-xl bg-[#F1F3FC] py-4 pl-4 pr-6">
					<h3 className="text-primary">Est. Wait Time</h3>
					<ul className="flex items-center text-[34px] font-extrabold text-primary">
						<li className="flex">
							<div className="flex flex-col">
								{estimatedWaitTime.hour
									.toString()
									.padStart(2, "0")}{" "}
								<p className="self-end text-[18px] font-normal">
									hrs
								</p>
							</div>
							<span className="mx-1.5">:</span>
						</li>

						<li className="flex flex-col">
							{estimatedWaitTime.minute
								.toString()
								.padStart(2, "0")}{" "}
							<p className="self-end text-[18px] font-normal">
								mins
							</p>
						</li>
					</ul>
				</div>
			</div>
			<div className="flex flex-col items-stretch space-y-2 border-t border-t-[#E5E5E7] px-6 py-3">
				<CheckInModal />
				<LeaveWaitlistModal />
				<p className="text-[10px] leading-[14px] tracking-[-0.2px] text-[#6D748D]">
					Note: Wait times are estimates and may vary. We aim for
					first-come, first-served, but exceptions may occur based on
					specific situations and management&apos;s discretion. Thank
					you for your understanding.
				</p>
			</div>
		</div>
	);
};

export default ViewWaitListDetails;
