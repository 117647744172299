import RequestIsLoading from "@/src/components/Loader/RequestIsLoading";
import { Button } from "@/src/components/ui/button";
import ViewWaitlistChat from "@/src/components/Waitlist/ViewWaitlistChat";
import ViewWaitListDetails from "@/src/components/Waitlist/ViewWaitListDetails";
import { useGetWaitlistInfo } from "@/src/store/slices/waitlist";
import useUserStore from "@/src/store/useUserStore";
import { cn } from "@/src/utils/general";
import { useQueryClient } from "@tanstack/react-query";
import React, { ReactNode } from "react";
import { Navigate, useParams } from "react-router";

const ViewWaitList: React.FC = () => {
	const getWaitlistInfoQuery = useGetWaitlistInfo();
	const { user } = useUserStore((s) => ({
		user: s.user,
	}));
	const queryClient = useQueryClient();
	const { waitlist_code } = useParams();
	const [currentTab, setCurrentTab] = React.useState<"details" | "chat">(
		"details"
	);

	if (
		getWaitlistInfoQuery.data?.data.waitlist_info.status === "done" ||
		getWaitlistInfoQuery.error?.status === 500
	) {
		return <Navigate to={"/waitlist/" + waitlist_code + "/done"} replace />;
	}

	return getWaitlistInfoQuery.isLoading ? (
		<>
			<div />
			<RequestIsLoading
				isLoading={getWaitlistInfoQuery.isLoading}
				isWhite
				isFullpage
				size={24}
			/>
		</>
	) : (
		<div className="p-4">
			<section className="flex flex-col space-y-5 rounded-xl bg-white pb-[22px] pl-9 pr-[26px] pt-5">
				<div className="flex items-center justify-between">
					<h1 className="text-[22px] font-semibold tracking-[-0.14px] text-main-1">
						Join the Waitlist
					</h1>
					<Button
						className="size-9 p-0"
						variant="outline"
						type="button"
						onClick={() => {
							queryClient.invalidateQueries({
								queryKey: ["waitlist-info"],
							});
						}}
					>
						<img
							src="/assets/icons/refresh-icon.svg"
							className={
								getWaitlistInfoQuery.isFetching
									? "animate-spin"
									: ""
							}
							alt="refresh"
						/>
					</Button>
				</div>
				<ul className="flex flex-col space-y-2">
					<li className="flex items-center space-x-2">
						<i className="mgc_building_5_line before:!text-primary" />
						<p className="font-bold text-main-1">
							{user?.waitlist_info?.station_name}
						</p>
					</li>
					<li className="flex items-center space-x-2">
						<i className="mgc_location_line before:!text-primary" />
						<p className="text-[#6D748D]">
							{user?.waitlist_info?.address}
						</p>
					</li>
					<li className="flex items-center space-x-2">
						<i className="mgc_building_1_line before:!text-primary" />
						<p className="text-main-1">
							{user?.waitlist_info?.business_name}
						</p>
					</li>
				</ul>
			</section>
			<section className="mt-6 flex flex-col rounded-xl bg-white ">
				<div className="flex items-center">
					<TabButton
						label={
							<h1
								className={cn(
									"text-[15px] font-semibold leading-[22px] text-[#858C95] transition-all duration-200 ease-in-out",
									{
										"text-primary":
											currentTab === "details",
									}
								)}
							>
								Details
							</h1>
						}
						isActive={currentTab === "details"}
						onClick={() => setCurrentTab("details")}
					/>
					{/* <TabButton
						label={
							<div className="flex items-center space-x-1">
								<h1
									className={cn(
										"text-[15px] font-semibold leading-[22px] text-[#858C95] transition-all duration-200 ease-in-out",
										{
											"text-primary":
												currentTab === "chat",
										}
									)}
								>
									Chat
								</h1>
								<span className="block h-4 w-4 rounded-full bg-destructive text-center text-xs text-white">
									2
								</span>
							</div>
						}
						isActive={currentTab === "chat"}
						onClick={() => setCurrentTab("chat")}
					/> */}
				</div>
				{currentTab === "details" && <ViewWaitListDetails />}
				{currentTab === "chat" && <ViewWaitlistChat />}
			</section>
		</div>
	);
};

export default ViewWaitList;

interface TabButtonProps {
	label: string | ReactNode;
	isActive: boolean;

	onClick: () => void;
}

const TabButton: React.FC<TabButtonProps> = ({ label, isActive, onClick }) => {
	return (
		<button
			className="flex flex-1 items-center justify-center border-b"
			type="button"
			onClick={onClick}
		>
			<div
				className={cn(
					"-mb-[1.5px] flex w-full items-center justify-center space-x-2 border-b-2 border-transparent py-2.5 transition-all duration-200 ease-in-out",
					{
						"border-b-primary": isActive,
					}
				)}
			>
				{label}
			</div>
		</button>
	);
};
